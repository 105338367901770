import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;

  justify-content: center;
  margin-left: 0rem;
  margin-right: 1rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const ButtonContainerNoMargin = styled(ButtonContainer)`
  margin-right: 0;
  margin-left: 0;
`;

const StyledLink = styled.a`
  background-image: linear-gradient(
    to right,
    #e53935 0%,
    #e35d5b 51%,
    #e53935 100%
  );

  padding: 15px 45px;
  text-align: center;
  border: none;
  outline: none;
  outline-width: 0;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  display: block;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  &:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    box-shadow: 0 15px 20px rgba(54, 38, 167, 0.4);
    transition: 0.2s ease;
  }
`;

const StyledButton = styled(motion.button)`
  /* border: 1px solid ${({ theme }) => theme.colors.primary.blue};
  background-color: ${({ theme }) => theme.colors.primary.blue}; */

  background-image: linear-gradient(
    to right,
    #e53935 0%,
    #e35d5b 51%,
    #e53935 100%
  );

  padding: 15px 45px;
  text-align: center;
  border: none;
  outline: none;
  outline-width: 0;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  display: block;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  font-size: 1.4rem;

  &:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    box-shadow: 0 15px 20px rgba(54, 38, 167, 0.4);
    transition: 0.2s ease;
  }

  /* color: white;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 1.5rem 2.5rem;
  border-radius: 5px;
   */

  cursor: pointer;
  /* &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary.blue};
    transition: 0.2s ease-in;
    color: ${({ theme }) => theme.colors.primary.blue};
    background-color: whitesmoke;
  } */
`;

const Button = ({
  buttonText,
  buttonColor,
  onClick,
  animate,
  initial,
  variants,
}) => {
  return (
    <ButtonContainer>
      <StyledButton
        initial={initial}
        animate={animate}
        variants={variants}
        buttonColor={buttonColor}
        onClick={onClick}
      >
        {buttonText}
      </StyledButton>
    </ButtonContainer>
  );
};

export default Button;
