// theme.js
export const theme = {
  background: {
    dark: "#292929",
    blue: "#18136b",
    black: "#0b0b0b",
    light: "whitesmoke",
  },

  colors: {
    primary: {
      light: "#EFFFFA",
      dark: " #2e2e2e",
      black: "#ffffff",
      blue: "#18136b",
    },
  },

  primaryDark: "#1a1a1a",
  primaryLight: "#EFFFFA",
  primaryHover: "#fcfcfc",
  primaryBackground: "whitesmoke",
  secondaryBackground: "#428af5",
  mobile: "850px",
};
