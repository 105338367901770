import React from "react";
import me from "../../assets/images/me_1.jpg";
import htmlLogo from "../../assets/images/htmlLogo.png";
import jsLogo from "../../assets/images/jsLogo.svg";
import reactLogo from "../../assets/images/reactLogo.png";
import tsLogo from "../../assets/images/tsLogo.png";
import cssLogo from "../../assets/images/cssLogo.png";
import firebaseLogo from "../../assets/images/firebaseLogo.png";
import mongoDBLogo from "../../assets/images/mongoDBLogo.png";
import nodejsLogo from "../../assets/images/nodejsLogo.png";
import postmanLogo from "../../assets/images/postmanLogo.png";
import gitLogo from "../../assets/images/gitLogo.png";
import cppLogo from "../../assets/images/cppLogo.png";
import cLogo from "../../assets/images/cLogo.png";
import sassLogo from "../../assets/images/sassLogo.png";
import reduxLogo from "../../assets/images/reduxLogo.png";
import redSplash from "../../assets/images/redSplash.png";
import netlifyLogo from "../../assets/images/netlifyLogo.png";
import herokuLogo from "../../assets/images/herokuLogo.png";
import expressLogo from "../../assets/images/expressLogo.png";

//style imports
import {
  Container,
  AboutTitleText,
  AboutMain,
  AboutDiv,
  AboutSection,
  AvatarDiv,
  AvatarImage,
  AboutTextDiv,
  AboutText,
  StackSection,
  StackTitle,
  TechText,
  TechSubTextDiv,
  TechDiv,
  TechSubText,
  ToolsDiv,
  TechImageDiv,
  TechImage,
  ToolsDivCenter,
  ToolsDivLeftEnd,
  ToolsDivLeft,
  ToolsDivRight,
  RedBackground,
  ToolsDivMargin,
} from "./style";

import Navbar from "../../components/Navbar";

const About = () => {
  const containerVariants = {
    hidden: {
      opacity: 0,
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: { delay: 0, duration: 0.2 },
    },
    exit: {
      opacity: 0,
      x: "-100vw",
      transition: {
        ease: "easeInOut",
      },
    },
  };

  const titleVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.3, duration: 0.3 },
    },
  };

  const techTitleVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.5, duration: 0.3 },
    },
  };

  const textVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.4, duration: 0.3 },
    },
    // exit: {
    //   opacity: 0,
    // },
  };

  const imageVaraint = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.1, duration: 0.3 },
    },
  };

  const techVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        // duration: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const toolsVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  };

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      x: 0,
    },
    out: {
      x: "-100vh",
    },
  };
  return (
    <Container
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      <AboutMain>
        <AboutSection>
          <AboutTextDiv>
            <AboutTitleText
              animate="visible"
              initial="hidden"
              variants={titleVariant}
            >
              About Me 😎
            </AboutTitleText>

            <AboutText
              animate="visible"
              initial="hidden"
              variants={textVariant}
            >
              I'm Blaine Oakley, a Software Developer and a University of South
              Florida alumnus. Upon graduating, I obtained a Bachelor of Science
              degree in Computer Engineering (Go Bulls! 🤘🏾).
            </AboutText>
            <AboutText variants={textVariant}>
              Although my degree placed a heavy focus on hardware programming,
              I've always had a particular interest in front-end web
              development. I really enjoy the ability to build complex UI, as
              well as piecing different components together to create a finished
              product that end-users love.
            </AboutText>
            <AboutText variants={textVariant}>
              My eagerness to learn and challenge myself with new technologies
              has also made me dabble with full-stack web applications.
            </AboutText>
          </AboutTextDiv>
          <AvatarDiv variants={imageVaraint}>
            <AvatarImage src={me} alt="me" />
          </AvatarDiv>
        </AboutSection>

        <StackSection>
          <StackTitle
            animate="visible"
            initial="hidden"
            variants={techTitleVariant}
          >
            Tech & Tools 🔨
          </StackTitle>
          <TechDiv animate="visible" initial="hidden" variants={techVariant}>
            <ToolsDivRight variants={toolsVariant}>
              <TechSubTextDiv>
                <TechSubText>Languages</TechSubText>
              </TechSubTextDiv>

              <TechImageDiv>
                <TechImage src={tsLogo} alt="typescript" />
                <TechImage src={jsLogo} alt="javascript" />
                <TechImage src={cppLogo} alt="c++" />
                <TechImage src={cLogo} alt="" />
              </TechImageDiv>
            </ToolsDivRight>

            <ToolsDivLeft variants={toolsVariant}>
              <TechSubTextDiv>
                <TechSubText>Front-end</TechSubText>
              </TechSubTextDiv>
              <TechImageDiv>
                <TechImage src={htmlLogo} alt="html" />
                <TechImage src={cssLogo} alt="css" />
                <TechImage src={reactLogo} alt="react" />
                <TechImage src={sassLogo} alt="sass" />
              </TechImageDiv>
            </ToolsDivLeft>

            <ToolsDivLeft variants={toolsVariant}>
              <TechSubTextDiv>
                <TechSubText>Back-end</TechSubText>
              </TechSubTextDiv>
              <TechImageDiv>
                <TechImage src={nodejsLogo} alt="nodejs" />
                <TechImage src={mongoDBLogo} alt="mongoDB" />
                <TechImage src={firebaseLogo} alt="firebase" />
                <TechImage src={expressLogo} alt="firebase" />
              </TechImageDiv>
            </ToolsDivLeft>

            <ToolsDivLeftEnd variants={toolsVariant}>
              <TechSubTextDiv>
                <TechSubText>Other Tools</TechSubText>
              </TechSubTextDiv>
              <TechImageDiv>
                <TechImage src={netlifyLogo} alt="netlify" />
                <TechImage src={herokuLogo} alt="heroku" />
                <TechImage src={postmanLogo} alt="postman" />
                <TechImage src={gitLogo} alt="git" />
              </TechImageDiv>
            </ToolsDivLeftEnd>
          </TechDiv>
        </StackSection>
      </AboutMain>
    </Container>
  );
};

export default About;
