import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import ProjectCard from "../../components/ProjectCard";

import googleClone from "../../assets/images/googleClone.PNG";
import bulu from "../../assets/images/bulu.PNG";

import waves1 from "../../assets/images/contactBackground1.svg";
import waves2 from "../../assets/images/contactBackground2.svg";
const ProjectContainer = styled.div`
  display: flex;

  justify-content: center;
  padding: 2rem 10rem;
  margin: auto;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;

    padding: 2rem;
  }
`;

const ProjectDiv = styled(motion.div)`
  /* width: 500px; */
`;

const ProjectMain = styled(motion.main)`
  /* padding: 2rem; */
  background-image: url(${waves2});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    background-image: url(${waves1});
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom;
  }
`;

const HeaderText = styled(motion.h1)`
  font-family: "Roboto Slab", sans-serif;
  font-size: 3rem;

  margin-bottom: 5rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-bottom: 2.5rem;
  }
`;

const Portfolio = () => {
  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.2, duration: 0.3 },
    },
    exit: {
      x: "-100vw",

      transition: { ease: "easeInOut" },
    },
  };

  // const titleVariant = {
  //   hidden: {
  //     opacity: 0,
  //   },
  //   visible: {
  //     opacity: 1,
  //     transition: { delay: 0, duration: 0.3 },
  //   },
  // };

  const projectDivVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        // duration: 0.3,
        duration: 0.3,
        delay: 0.2,
        staggerChildren: 0.7,
      },
    },
  };

  return (
    <ProjectMain
      animate="visible"
      initial="hidden"
      exit="exit"
      variants={containerVariants}
    >
      <ProjectContainer>
        <ProjectDiv variants={projectDivVariant}>
          <HeaderText>Projects 🗂️ </HeaderText>
          <ProjectCard
            projectTitle="Bulu"
            projectImage={bulu}
            projectDescription="Movie site designed to show all the latest, trending movies and genres from The Movie Database API"
            projectTech="#HTML #CSS #JavaScript #React"
            projectLink="https://bulu.netlify.app/"
            projectCode="https://github.com/Blaine876/Bulu"
          />

          <ProjectCard
            projectTitle="Boogle"
            projectImage={googleClone}
            projectDescription="Search engine tool inspired by Google design that retrieves data from Google Custom Search API"
            projectTech="#HTML #CSS3 #JavaScript #React"
            projectLink="https://booglee.netlify.app/"
            projectCode="https://github.com/Blaine876/Boogle"
          />

          {/* <ProjectCard
            projectTitle="Boogle"
            projectImage={googleClone}
            projectDescription="Search engine tool inspired by Google design that retrieves data from The Movie Database  API"
            projectTech="#HTML #CSS3 #JavaScript #React"
          /> */}
        </ProjectDiv>
      </ProjectContainer>
    </ProjectMain>
  );
};

export default Portfolio;
