import styled from "styled-components";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const StyledNavbar = styled.nav`
  width: 7rem;

  padding: 2rem 0rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    height: 7rem;
    padding: 2rem;
    position: static;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 90vh; /* flex-direction: column;
  align-items: center;
  justify-content: space-between; */

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const GithubLink = styled(GitHubIcon)`
  && {
    font-size: 3rem;
    color: black;

    &:hover {
      color: #f90b44;
      opacity: 1;
      transition: 0.3s;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    && {
      margin-bottom: 0rem;
    }
  }
`;

const LinkedInLink = styled(LinkedInIcon)`
  && {
    color: #000000;
    font-size: 3rem;

    &:hover {
      opacity: 1;
      color: #f90b44;
      transition: 0.3s;
    }
  }
`;

const NavLists = styled.ul`
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: row;
  }
`;

const NavList = styled.li`
  list-style: none;
  margin-right: 1rem;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  font-family: "Roboto Slab", sans-serif;
  border: 1px solid black;
  border-radius: 5px;
  color: black;
  padding: 0.3rem;
  font-size: 2rem;

  /* padding-left: 0.5rem;
  padding-right: 0.5rem; */

  &:hover {
    color: #f90b44;
    transition: 0.2s;
    border: 1px solid #f90b44;
  }
`;

const TopNavDiv = styled.div`
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.mobile}) {
  }
`;

const BottomNavDiv = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.mobile}) {
  }
`;

const Navbar = () => {
  const history = useHistory();
  return (
    <StyledNavbar>
      <NavbarContainer>
        <TopNavDiv>
          <NavLink to="/">BO</NavLink>
        </TopNavDiv>

        <BottomNavDiv>
          <NavLists>
            <NavList>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/Blaine876"
              >
                <GithubLink />
              </a>
            </NavList>

            <NavList>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/blaine-oakley/"
              >
                <LinkedInLink />
              </a>
            </NavList>
          </NavLists>
        </BottomNavDiv>
      </NavbarContainer>
    </StyledNavbar>
  );
};

export default Navbar;
