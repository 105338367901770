import React from "react";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import styled from "styled-components";

const FooterContainer = styled.footer`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 3rem;
  right: 3rem;
  background-color: ${({ theme }) => theme.background.dark};
  padding: 1rem;
`;

const FooterText = styled.p`
  font-size: 1.4rem;
  text-align: center;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;

const FooterLink = styled.a`
  margin-bottom: 1rem;
  &:hover {
    color: ${({ theme }) => theme.colors.primary.blue};
  }
`;

const FooterGithub = styled(GitHubIcon)`
  && {
    color: black;
    font-size: 3rem;
    &:hover {
      color: ${({ theme }) => theme.colors.primary.blue};
      transition: 0.3s;
    }
  }
`;

const FooterLinkedIn = styled(LinkedInIcon)`
  && {
    color: black;
    font-size: 3rem;
    &:hover {
      color: ${({ theme }) => theme.colors.primary.blue};
      transition: 0.3s;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLinks>
        <FooterLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/Blaine876"
        >
          <FooterGithub />
        </FooterLink>

        <FooterLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/blaine-oakley/"
        >
          <FooterLinkedIn />
        </FooterLink>
      </FooterLinks>
    </FooterContainer>
  );
};

export default Footer;
