import React, { createContext, useReducer } from "react";
import globalReducer from "./globalReducer";
const initialState = {
  sidebar: false,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  function setSidebar(sidebar) {
    dispatch({
      type: "TOGGLE_SIDEBAR",
      payload: !sidebar,
    });
  }

  return (
    <GlobalContext.Provider value={{ sidebar: state.sidebar, setSidebar }}>
      {children}
    </GlobalContext.Provider>
  );
};
