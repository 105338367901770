export default (state, action) => {
  console.log(action);

  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        sidebar: action.payload,
      };

    default:
      return state;
  }
};
