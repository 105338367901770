import React from "react";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import { useHistory } from "react-router-dom";
import me from "../../assets/images/me.jpg";
import mac from "../../assets/images/mac.jpg";
import About from "../About";

import {
  MainContainer,
  HeroSection,
  Image,
  ImageDiv,
  TitleDiv,
  Title,
  SubTitle,
  HeroImageDiv,
  HeroImage,
  ButtonDiv,
  Divider,
  HomeMain,
  HomeDiv,
  WaveSpan,
  GreetingsDiv,
} from "./style";
import { transform } from "framer-motion";

const Home = () => {
  const history = useHistory();

  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.5, duration: 0.4 },
    },
    exit: {
      x: "-100vw",

      transition: { ease: "easeInOut" },
    },
  };

  const button1Variants = {
    hidden: {
      opacity: 0,
      x: "-100vw",
    },
    visible: {
      x: "0",
      opacity: 1,
      transition: { delay: 0.5, duration: 0.5 },
    },
  };

  const button2Variants = {
    hidden: {
      opacity: 0,
      x: "-100vw",
    },
    visible: {
      x: "0",
      opacity: 1,
      transition: { delay: 0.5, duration: 0.7 },
    },
  };

  const button3Variants = {
    hidden: {
      opacity: 0,
      x: "-100vw",
    },
    visible: {
      x: "0",
      opacity: 1,
      transition: { delay: 0.5, duration: 0.9 },
    },
  };

  return (
    <HomeMain
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      <HomeDiv>
        <MainContainer>
          <HeroSection>
            <TitleDiv>
              <SubTitle>
                hello there <WaveSpan>👋🏾</WaveSpan>
              </SubTitle>

              <Title>I'm Blaine.</Title>
            </TitleDiv>
            <ButtonDiv>
              <Button
                animate="visible"
                initial="hidden"
                variants={button3Variants}
                buttonText="About Me"
                onClick={() => history.push("/about")}
              />

              <Button
                animate="visible"
                initial="hidden"
                variants={button2Variants}
                buttonText="Projects"
                onClick={() => history.push("/portfolio")}
              />

              <Button
                animate="visible"
                initial="hidden"
                variants={button1Variants}
                buttonText="Contact"
                onClick={() => history.push("/contact")}
              />
            </ButtonDiv>
          </HeroSection>
        </MainContainer>
      </HomeDiv>
    </HomeMain>
  );
};

export default Home;
