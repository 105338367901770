import styled from "styled-components";
import waves from "../../assets/images/contactBackground1.svg";
import waves1 from "../../assets/images/contactBackground2.svg";
import avatar from "../../assets/images/me.jpg";
import { motion } from "framer-motion";
//SECTION
export const AboutMain = styled.main`
  margin: auto;
  height: 100%;
  max-width: 1260px;
  padding: 2rem 10rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 2rem;
  }
  /* 
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */
`;

export const AboutSection = styled.section`
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    /* display: flex; */
    flex-direction: column-reverse;
    /* justify-content: space-evenly; */
    /* align-items: center; */
    margin-bottom: 2rem;
  }
`;

export const StackSection = styled.section`
  display: flex;

  flex-direction: column;
  /* background-color: ${({ theme }) => theme.background.light}; */
`;

//  DIV
export const Container = styled(motion.div)`
  min-height: 100vh;
  width: 100%;
  padding: 2rem;
  background-image: url(${waves});
  background-repeat: no-repeat;
  background-size: cover;

  /* background-image: url(${waves});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom; */

  /* background-color: #0b0b0b; */

  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    background-size: cover;
    background-position: left;
  }
`;

export const AboutTextDiv = styled(motion.div)`
  font-family: "Poppins", sans-serif;
  display: flex;
  margin-top: 2.5rem;
  max-width: 50rem;
  margin-right: 3rem;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-right: 0;
  }
`;

export const AvatarDiv = styled(motion.div)`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

export const TechDiv = styled(motion.div)`
  display: flex;

  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
`;
export const ToolsDiv = styled(motion.div)`
  display: flex;
  width: 100%;
  z-index: 10;
  flex-direction: column;

  //#eb3349 →

  /* background: #ef3b36; fallback for old browsers */
  /* background: -webkit-linear-gradient(
    to right,
    #eb3349,
    #f45c43
  ); /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(
    to right,
    #eb3349,
    #f45c43
  );  */
  /* background-color: ${({ theme }) => theme.background.light}; */
  /* border: 1px solid lightgray; */
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);

  transition: 0.25s ease-in;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    /* border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`;

export const ToolsDivTop = styled(ToolsDiv)``;

export const ToolsDivLeft = styled(ToolsDiv)`
  margin-left: 1rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-left: 0rem;
    margin-right: 0rem;
    border-radius: 1rem;
  }
`;

export const ToolsDivLeftEnd = styled(ToolsDiv)`
  margin-left: 1rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    /* border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem; */
    margin-left: 0rem;
    margin-right: 0rem;
    border-radius: 1rem;
  }
`;

export const ToolsDivRight = styled(ToolsDiv)`
  margin-right: 1rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const ToolsDivCenter = styled(ToolsDiv)`
  margin-left: 1rem;
  margin-right: 1rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const TechSubTextDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TechImageDiv = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 3rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: row;

    width: 100%;
  }
`;

//TEXT
export const AboutTitleText = styled(motion.h1)`
  font-family: "Roboto Slab", sans-serif;
  font-size: 3rem;
  text-align: left;
  margin-bottom: 5rem;
  color: ${({ theme }) => theme.colors.primary.dark};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-bottom: 2.5rem;
  }
`;

export const AboutText = styled(motion.p)`
  font-family: "Poppins", sans-serif;
  color: ${({ theme }) => theme.colors.primary.dark};
  font-weight: 500;
  font-size: 1.6rem;
  /* padding-left: 10%;
  padding-right: 10%; */
  margin-bottom: 1rem;
  text-align: justify;
`;

export const Text = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.primary.dark};
  text-align: justify;
`;

export const SubText = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.primary.dark};
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const StackTitle = styled(motion.h1)`
  font-family: "Roboto Slab", sans-serif;
  font-size: 3rem;
  margin-bottom: 1rem;

  color: ${({ theme }) => theme.colors.primary.dark};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-family: "Poppins", sans-serif;
    font-size: 2.4rem;
    margin-bottom: 1rem;
    text-align: left;

    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const TechText = styled(Text)`
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
`;

export const TechSubText = styled(TechText)`
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary.dark};
`;

// IMAGE
export const AvatarImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: 30rem;
  background-image: url(${avatar});
  background-size: cover;

  border-radius: 999px;
  object-fit: contain;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 200px;
    height: 200px;
  }
`;
export const TechImage = styled.img`
  width: 8rem;
  height: 8rem;
  margin: 1rem;
  object-fit: contain;
`;

export const RedBackground = styled.img`
  position: absolute;
  height: 300px;
  width: 100%;
  object-fit: contain;
  z-index: -1;
`;
