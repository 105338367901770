import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import mac from "../../assets/images/mac.jpg";
import waves from "../../assets/images/contactBackground.svg";

export const HomeMain = styled(motion.main)`
  height: 100vh;
  overflow: hidden;

  background-image: url(${waves});
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 2rem;
    background-position: right;
    background-size: cover;
  }
`;

export const HomeDiv = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
  }
`;

export const WaveSpan = styled(motion.span)`
  font-size: 3rem;
  /* transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transition: 0.4 ease-in; */
`;

export const MainContainer = styled.div`
  /* background-color: #262626; */
  /* background-color: ${({ theme }) => theme.background.dark}; */
  /* background: rgb(42, 42, 42);
  background: linear-gradient(
    6deg,
    rgba(42, 42, 42, 1) 0%,
    rgba(0, 0, 0, 0.9752275910364145) 100%
  ); */
  /* background: #0b0b0b; */

  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const HeroImageDiv = styled.div`
  position: relative;
  z-index: -1;
`;

export const HeroImage = styled.img`
  position: absolute;

  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;
  left: 0;
  right: 0;
  width: auto;
  min-width: 100%;
  height: 100%;
  filter: blur(8px);
  -webkit-filter: blur(8px);
`;

export const HeroSection = styled.section`
  /* z-index: 5; */

  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 3rem;

  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Image = styled.img`
  min-height: 10rem;
  min-width: 10rem;
  max-height: 30rem;
  max-width: 30rem;
  object-fit: contain;
  border-radius: 999px;
`;

export const TitleDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Title = styled.p`
  margin-left: 3rem;
  font-size: 6.4rem;

  font-family: "Roboto Slab", sans-serif;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.primary.dark};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    text-align: center;
    margin-left: 0;
    font-size: 5.4rem;
  }
`;

export const SubTitle = styled(Title)`
  font-size: 4rem;
`;
