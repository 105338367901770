import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const CardContainer = styled(motion.div)`
  display: flex;

  align-items: center;
  border-radius: 5px;
  justify-content: center;
  flex-wrap: wrap;
  /* border: 1px solid lightgray;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2); */
  /* background-color: mintcream; */
  margin-bottom: 2rem;
`;

const ProjectImageTitleDiv = styled.div`
  margin-right: 3rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

const ProjectTitle = styled.h2`
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
`;

const ProjectImage = styled.img`
  height: 100%;
  width: 500px;
  /* border: 1px solid grey; */
  object-fit: contain;
  border-radius: 1rem;
  /* border: 1px solid lightgray; */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    /* height: 250px; */
  }
`;

const ProjectDescription = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 3rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    text-align: center;
  }
`;

const ProjectTech = styled(ProjectDescription)`
  font-size: 1.4rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    text-align: center;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content: space-around;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  background-image: linear-gradient(
    to right,
    #e53935 0%,
    #e35d5b 51%,
    #e53935 100%
  );

  padding: 15px 45px;
  text-align: center;
  border: none;
  outline: none;
  outline-width: 0;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  border-radius: 10px;
  display: block;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  &:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    box-shadow: 0 15px 20px rgba(54, 38, 167, 0.4);
    transition: 0.2s ease;
  }
`;

const ProjectInfoDiv = styled.div`
  max-width: 400px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`;

const ProjectCard = ({
  projectTitle,
  projectDescription,
  projectTech,
  projectImage,
  projectLink,
  projectCode,
}) => {
  const projectCardVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  };

  return (
    <CardContainer variants={projectCardVariant}>
      <ProjectImageTitleDiv>
        <ProjectTitle>{projectTitle}</ProjectTitle>

        <ProjectImage src={projectImage} />
      </ProjectImageTitleDiv>

      <ProjectInfoDiv>
        <ProjectDescription>{projectDescription}</ProjectDescription>

        <ProjectTech>{projectTech}</ProjectTech>

        <ButtonDiv>
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            // href="https://clone-a1fab.web.app/"
            href={projectLink}
          >
            Live Demo
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            // href="https://github.com/Blaine876/Google-Clone"
            href={projectCode}
          >
            Code
          </StyledLink>
        </ButtonDiv>
      </ProjectInfoDiv>
    </CardContainer>
  );
};

export default ProjectCard;
