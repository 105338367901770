import React, { useState } from "react";
import { motion } from "framer-motion";
import Button from "../../components/Button";
import styled from "styled-components";
import waves from "../../assets/images/contactBackground1.svg";

const Container = styled(motion.div)`
  padding: 2rem;
  height: 100vh;
  overflow: hidden;
  background-image: url(${waves});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
`;

const ContactMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 10rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0;
  }
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
`;

const HeaderText = styled.h1`
  font-size: 3rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-family: "Roboto Slab", sans-serif;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    text-align: left;
  }
`;

const ContactText = styled.p`
  font-family: "Poppins", sans-serif;
  margin-bottom: 2rem;
  font-size: 1.6rem;
`;

const FormSection = styled.section`
  display: flex;
  width: 500px;
  min-width: 270px;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`;

const StyledForm = styled(motion.form)`
  display: flex;
  /* width: 100%; */
  padding: 1rem;
  flex-direction: column;
  /* align-items: center; */
`;

const InputField = styled(motion.input)`
  font-size: 1.4rem;
  border-radius: 5px;
  border: 1px solid lightgray;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
  outline: none;
  outline-width: 0;
  width: 100%;
  padding: 1rem;

  /* @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  } */
`;

const TextAreaField = styled(motion.textarea)`
  outline: none;
  font-family: "Poppins", sans-serif;
  border: 1px solid lightgray;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  outline-width: 0;
  width: 100%;
  height: 20rem;
  font-size: 1.4rem;
`;

const Contact = () => {
  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.3, duration: 0.5 },
    },
    exit: {
      x: "-100vw",

      transition: { ease: "easeInOut" },
    },
  };

  const formVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        // duration: 0.3,
        delay: 0.5,
        staggerChildren: 0.3,
      },
    },
  };

  const inputVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");

  const sendMessage = () => {
    console.log("Message received");
  };

  return (
    <Container
      animate="visible"
      initial="hidden"
      exit="exit"
      variants={containerVariants}
    >
      <ContactMain>
        <FormSection>
          <HeaderDiv>
            <HeaderText>Contact Me 📪</HeaderText>
            <ContactText>Let's keep in touch!</ContactText>
          </HeaderDiv>

          <StyledForm variants={formVariant}>
            <InputField
              variants={inputVariant}
              placeholder="Full Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <InputField
              variants={inputVariant}
              placeholder="Email address"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <InputField
              variants={inputVariant}
              placeholder="Subject"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <TextAreaField
              variants={inputVariant}
              placeholder="Your Message"
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button buttonText="send message" onClick={sendMessage} />
          </StyledForm>
        </FormSection>
      </ContactMain>
    </Container>
  );
};

export default Contact;
